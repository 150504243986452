import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { SectionTitle } from "../SectionTitle";
import { TestifyCard } from "../TestifyCard";
const testifyArray = [
  {
    src: "user-testify",
    authorThumbnail: "/moiz-bhai.png",
    authorName: "Moiz Bhai",
    authorCompany: "Beauty Drapers , Mubarak Manzil",
    authorReview:
      "Got more than the height decided and a very smooth experience with the team, Thankful to Raj Builders and Taha Bhai for their work!",
  },
];
const Testify = () => {
  return (
    <>
      <section className="testify__wrap section__row">
        <div className="main__container">
          <SectionTitle title="Our Happy Customers" />
          <div className="testify__container">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              navigation={true}
              pagination={{
                type: "fraction",
              }}
              modules={[Navigation, Pagination]}
              className="testifyMySwiper"
            >
              {testifyArray?.map((item, index) => (
                <SwiperSlide key={index}>
                  <TestifyCard
                    src={item.src}
                    authorThumbnail={item.authorThumbnail}
                    authorName={item.authorName}
                    authorCompany={item.authorCompany}
                    authorReview={item.authorReview}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export { Testify };
