import React from "react";
import { Anchor } from "../Anchor";
import { ProjectCard } from "../ProjectCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { isMobile } from "../../Common/utils";
const heroBannerArray = [
  {
    isVideo: true,
    posterImg: "hero-banner-slide1.jpg",
    // src: "footage",
    thumbnail:
      "https://www.youtube.com/embed/oioyCkgAuko?si=SqiXNNLxtCDfdIhU&enablejsapi=1",
    // title: "Project name1",
    // venue: "Location",
  },
  // {
  //   thumbnail: "thumb1.jpg",
  //   title: "Project name2",
  //   venue: "Location",
  // },
];
const heroContent = (
  <>
    <article className="hp__banner-caption">
      <span>
        Find your perfect home with Raj Builders. We offer a wide range of
        housing options designed to suit every family's needs.
      </span>
    </article>
    <div className="hp__banner-cta">
      <Anchor
        linkCls="btn btn__link noClick"
        linkText="Our projects"
        hyperLink="/projects"
        linkTarget="_self"
      />
    </div>
  </>
);
const HeroBanner = () => {
  return (
    <>
      <section className="hp__banner">
        <div className="main__container">
          <div className="hp__banner-grid">
            <div className="hp__banner-gridLHS">
              <h1 className="hp__banner-title">
                <span>The Address to your new lifestyle </span>
              </h1>

              {!isMobile() && heroContent}
            </div>
            <div className="hp__banner-gridRHS">
              <div className="hp__banner-swiper">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={0}
                  grabCursor={true}
                  centeredSlides={true}
                  loop={true}
                  navigation={true}
                  pagination={{
                    type: "fraction",
                  }}
                  modules={[Navigation, Pagination]}
                  className="heroBannerMySwiper"
                >
                  {heroBannerArray?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <ProjectCard
                        src={item.src}
                        thumbnail={item.thumbnail}
                        posterImg={item.posterImg}
                        title={item.title}
                        venue={item.venue}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            {isMobile() && (
              <div className="hp__banner-gridLHS">{heroContent}</div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export { HeroBanner };
