import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
const foo = `javascript:void(0)`;
const Tabs = ({
  extraCls,
  tabConfig,
  isIcon,
  updateActiveTab = () => {},
  activeTab,
}) => {
  return (
    <>
      <div className={`tab ${extraCls ? `tab--${extraCls}` : ""}`}>
        <ul>
          {tabConfig.map((item, index) => (
            <li key={index}>
              <a
                id={item.title.toLowerCase()}
                href={foo}
                onClick={(e) => {
                  e.preventDefault();
                  updateActiveTab(item.title);
                }}
                className={`${item.title === activeTab ? "active" : ""}`}
              >
                {isIcon ? (
                  <>
                    {/* <i className={item.icon}></i> */}
                    <img
                      src={`${IMAGE_BASE_URL}/icons/${item?.icon}.svg?v=${IMAGE_VERSION}`}
                      alt={`${item.title.toLowerCase()}-icon`}
                      loading="lazy"
                    />
                  </>
                ) : (
                  ""
                )}

                <span>{item.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export { Tabs };
