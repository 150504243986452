import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../../Common/constants";

const ImageGalleryArr = [
  {
    imgPath: "gallery",
    imgName: "1.png",
  },
  {
    imgPath: "gallery",
    imgName: "2.png",
  },
  {
    imgPath: "gallery",
    imgName: "3.png",
  },
  {
    imgPath: "gallery",
    imgName: "4.png",
  },
  {
    imgPath: "gallery",
    imgName: "5.png",
  },
  {
    imgPath: "gallery",
    imgName: "6.png",
  },
  {
    imgPath: "gallery",
    imgName: "7.png",
  },
];

const ImageGallery = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <div className="imgGallery__swiper">
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {props?.images?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="imgGallery__big">
                <img
                  src={`${
                    item.imgPath
                      ? `${IMAGE_BASE_URL}/${item.imgPath}`
                      : `${IMAGE_BASE_URL}`
                  }/${
                    item.imgName ? item.imgName : "placeholder.jpg"
                  }?v=${IMAGE_VERSION}`}
                  alt={item.imgName}
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={"auto"}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper--miniThumb"
        >
          {ImageGalleryArr.map((itemThumbnail, indexThumbnail) => (
            <SwiperSlide key={indexThumbnail}>
              <div className="imgGallery__small">
                <img
                  src={`${
                    itemThumbnail.imgPath
                      ? `${IMAGE_BASE_URL}/${itemThumbnail.imgPath}`
                      : `${IMAGE_BASE_URL}`
                  }/${
                    itemThumbnail.imgName
                      ? itemThumbnail.imgName
                      : "placeholder.jpg"
                  }?v=${IMAGE_VERSION}`}
                  alt={itemThumbnail.imgName}
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export { ImageGallery };
