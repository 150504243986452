import React from "react";
import { SectionTitle } from "../SectionTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const abtUsValueArr = [
  {
    title: "Excellence",
    content:
      "We strive for excellence in everything we do, from the quality of our homes to the level of service we provide. We hold ourselves to the highest standards and continuously seek ways to surpass them.",
  },
  {
    title: "Integrity",
    content:
      "Integrity is the foundation of our business. We conduct ourselves with honesty, transparency, and ethical behavior in all our interactions, earning the trust and respect of our clients, partners, and communities.",
  },
  {
    title: "Collaboration",
    content:
      "Collaboration is at the heart of our approach. We believe in fostering strong partnerships with our clients, employees, and stakeholders, working together as a unified team to achieve shared goals and mutual success.",
  },
  {
    title: "Excellence",
    content:
      "We strive for excellence in everything we do, from the quality of our homes to the level of service we provide. We hold ourselves to the highest standards and continuously seek ways to surpass them.",
  },
  {
    title: "Integrity",
    content:
      "Integrity is the foundation of our business. We conduct ourselves with honesty, transparency, and ethical behavior in all our interactions, earning the trust and respect of our clients, partners, and communities.",
  },
  {
    title: "Collaboration",
    content:
      "Collaboration is at the heart of our approach. We believe in fostering strong partnerships with our clients, employees, and stakeholders, working together as a unified team to achieve shared goals and mutual success.",
  },
  {
    title: "Excellence",
    content:
      "We strive for excellence in everything we do, from the quality of our homes to the level of service we provide. We hold ourselves to the highest standards and continuously seek ways to surpass them.",
  },
  {
    title: "Integrity",
    content:
      "Integrity is the foundation of our business. We conduct ourselves with honesty, transparency, and ethical behavior in all our interactions, earning the trust and respect of our clients, partners, and communities.",
  },
  {
    title: "Collaboration",
    content:
      "Collaboration is at the heart of our approach. We believe in fostering strong partnerships with our clients, employees, and stakeholders, working together as a unified team to achieve shared goals and mutual success.",
  }
];

const AboutUsValues = () => {
  return (
    <>
      <section className="aboutUs__values section__row">
        <div className="main__container">
          <SectionTitle title="Our Values" />
        </div>
        <div className="main__container">
          <div className="aboutUs__values-swiper">
            <Swiper
              slidesPerView={"auto"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                "@0.00": {},
                "@0.75": {
                  slidesPerView: "auto",
                },
              }}
              className="ourValuesMySwiper"
            >
              {abtUsValueArr.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="aboutUs__values-box">
                    <h2 className="aboutUs__values-boxTitle">
                      <span>{item.title}</span>
                    </h2>
                    <div className="aboutUs__values-boxContent">
                      {item.content}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export { AboutUsValues };
