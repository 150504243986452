import React from "react";

const SectionTitle = ({ title, extraCls }) => {
  return (
    <>
      <h2 className={`section__title ${extraCls || ""}`}>{title}</h2>
    </>
  );
};

export { SectionTitle };
