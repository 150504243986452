import React, { useCallback, useEffect } from "react";
import { CtaButton } from "../Buttons";

function Overlays({
  show = false,
  component,
  overlayName,
  overlayTitle,
  overlaySubTitle,
  btnPrimary,
  btnSecondary = "Cancel",
  btnPrimaryIcon,
  overlayConfig,
  onCancelClick = () => {
    return null;
  },
  handleSubmit = () => {},
}) {
  const {
    closeBtn = false,
    submitBtn = true,
    cancelBtn = false,
    showOverlayTitle = true,
    showOverlaySub_Title = false,
    noOverlayFoot = true,
    noOverlayBody,
  } = overlayConfig;

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape" && cancelBtn) {
        onCancelClick();
      }
    },
    [onCancelClick, cancelBtn]
  );
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    const BODY_CLASS = "noBodyScroll";
    if (show) {
      document.body.classList.add(BODY_CLASS);
    } else {
      document.body.classList.remove(BODY_CLASS);
    }
  }, [show]);

  return (
    <>
      <div className={`popup__wrap ${overlayName} fade ${show ? "in" : "out"}`}>
        {/* in | out */}
        <div className=" popup__container">
          {closeBtn && (
            <button className="popup__close" onClick={() => onCancelClick()}>
              <i className="rb-close"></i>
            </button>
          )}
          <div className="popup__head">
            {showOverlayTitle && (
              <>
                <div className="popup__heading">
                  <span>{overlayTitle}</span>
                </div>
              </>
            )}
            {showOverlaySub_Title && (
              <div className="popup__heading-sub">
                <span>{overlaySubTitle}</span>
              </div>
            )}
          </div>
          {noOverlayBody && <div className="popup__body">{component}</div>}
          {noOverlayFoot && (
            <div className="popup__foot">
              {cancelBtn && (
                <CtaButton
                  btnCls="btn btn__secondary btn__secondary--outline"
                  iconCls=""
                  btnText={btnSecondary}
                />
              )}
              {submitBtn && (
                <CtaButton
                  btnCls="btn btn__secondary"
                  iconCls={btnPrimaryIcon}
                  btnText={btnPrimary}
                  onClickProp={handleSubmit}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { Overlays };
